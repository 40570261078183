import * as React from 'react'


import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";
import { colors, fonts } from '../styles/variables'

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';







import HomeSlider from '../components/home/HomeSlider'



import Highlight from '../components/home/Highlight'
import { FormattedMessage } from 'react-intl';
import Consent from '../components/cookie/Consent'
import HomeNewsletter from '../components/newsletter/HomeNewsletter'




const ComingSoon = styled.div`
width:100%;
display:flex;
justify-content:center;
letter-spacing:1px;



.pip {
  width: 24px;
  height: 24px;
  margin: 7px;
  background-color: rgba(238,238,238,.2);
  border-radius: 100%;
  transition: all .3s ease-in;
}

.pip--on {
  background-color: #994095;
  transform: scale(1.1);
}



.binary-digit-group {
  display: flex;
  margin: 0 10px;
}
`

const Claimer = styled.div`
width:100%;
display:flex;
justify-content:center;
letter-spacing:1px;
margin-top:1rem;
margin-bottom:10rem;

  @media screen and (max-width: 400px) {

display:block;
   }

`

const LilaHase = styled.div`
padding-bottom:0.2rem;
padding-top:20vh;
width:100%;
display:flex;
justify-content:center;
letter-spacing:1px;



.pip {
  width: 24px;
  height: 24px;
  margin: 7px;
  background-color: rgba(238,238,238,.2);
  border-radius: 100%;
  transition: all .3s ease-in;
}

.pip--on {
  background-color: #994095;
  transform: scale(1.1);
}



.binary-digit-group {
  display: flex;
  margin: 0 10px;
}
`



const P1 = styled.div`
font-family:${fonts.bcHairLine};
font-size:0.7rem !important;
text-align:center;
letter-spacing:0.65rem;

display:flex;
justify-content:center;
width:40px;

  @media screen and (max-width: 400px) {
width:100%;
text-align:center;


   }

`
const P2 = styled.div`
font-family:${fonts.bcHairLine};
font-size:0.7rem !important;
text-align:center;
letter-spacing:0.65rem;
display:flex;
justify-content:center;
width:180px;


  @media screen and (max-width: 400px) {
width:100%;
text-align:center;


   }
`
const P3 = styled.div`
font-family:${fonts.bcHairLine};
font-size:0.7rem !important;
text-align:center;
letter-spacing:0.65rem;
display:flex;
justify-content:center;
width:auto;


  @media screen and (max-width: 400px) {
width:100%;
text-align:center;


   }
`



const CountdownWrapper = styled.div`

.cls-1{fill:var(--txt);}

button,
input {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

::-webkit-input-placeholder {
  color: rgba(153, 153, 153, 0.65);
}

::-moz-placeholder {
  color: rgba(153, 153, 153, 0.65);
}

:-ms-input-placeholder {
  color: rgba(153, 153, 153, 0.65);
}

::-ms-input-placeholder {
  color: rgba(153, 153, 153, 0.65);
}

::placeholder {
  color: rgba(153, 153, 153, 0.65);
}

.app {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  min-height: 100%;
}
.app__title {
  font-weight: 400;
  margin: 1rem auto 2rem;
  text-align: center;
}

.clock {

  @media screen and (max-width: 600px) {
flex-direction:row;
width: auto;
justify-content:center;


   }
width:527px;

  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
          justify-content: space-between;
  margin-bottom: 2rem;
}
.clock__display {
  height: 120px;
  position: relative;
  width: 120px;


    @media screen and (max-width: 600px) {
margin-bottom:1em;

   }
}
.clock__text {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
          justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
.clock__amount {
  font-size: 2.2rem;
}
.clock__unit {
  font-size: 0.75rem;
}

svg{

}

.circle {
  height: 120px !important;
  width: 120px !important;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transition: stroke-dashoffset 0.4s cubic-bezier(0.4, 0, 0, 1.7);
  transition: stroke-dashoffset 0.4s cubic-bezier(0.4, 0, 0, 1.7);
}

.form {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  margin-bottom: 1rem;
}

.btn,
.form__field {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0.5rem;
}

.form__field {
  flex-shrink: 1;
}

.btn {
  background-color: #994095;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  flex-shrink: 1;
}

.message {
  margin: 0 auto 1rem;
  text-align: center;
}
.message--error {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.6);
  padding: 1rem;
}

`






const Wrapper = styled.div`


`







const HighlightWrap = styled.div`
width:100%;
//  background:white;


`



const Cbd = styled.div`
width:100%;
justify-content:center;
display:flex;
font-family:${fonts.bcHairLine};
margin-bottom:1rem;
`

const CbdP = styled.div`
width:154.6px;
display:flex;
font-size:1.2rem;
justify-content:space-between;`

const Lh = styled.div`
width:152px;

.cls-1{fill:var(--hase);}.cls-2{fill:none;}.cls-3{fill:var(--hase);}
`



const Span = styled.span`
transition:all .2s ease;

:hover{
color:var(--hase);
transform: scale(1.13);

}
`

const LilaWelcome = styled.div`

background:var(--bg);
`


const SomethingNew= styled.div`
padding-top:10%;
padding-bottom:10%;
padding-right:10%;


padding-left:11%;

align-items:center;



  @media screen and (max-width:600px){
  }





`





const LilaLine1 = styled.div`
display:flex;
font-family:${fonts.bcRoman};
font-size:2.9rem;
margin-bottom:2.3rem;
color:var(--hase);

  @media screen and (max-width:600px){
  font-size:2.1rem;

  }

  @media screen and (max-width:515px){
  font-size:1.8rem;

  }


`
const LilaLine2 = styled.div`
display:flex;
margin-bottom:1rem;
font-size:1.2rem;

font-family:${fonts.bcHairLine};

  @media screen and (max-width:600px){
  font-size:1rem;

  }

    @media screen and (max-width:515px){
  font-size:0.9rem;

  }



`
const LilaLine3 = styled.div`
display:flex;
font-size:2.3rem;
font-family:${fonts.bcHairLine};

  @media screen and (max-width:600px){
  font-size:1.9rem;

  }

    @media screen and (max-width:515px){
  font-size:1.6rem;

  }



`
const LilaLine4 = styled.div`
display:flex;
font-size:2.3rem;
margin-bottom:1.3rem;
margin-left:2px;
font-family:${fonts.bcHairLine};

  @media screen and (max-width:600px){
  font-size:1.9rem;

  }

    @media screen and (max-width:515px){
  font-size:1.6rem;

  }


`
const LilaLine5 = styled.div`
display:flex;
font-size:2.3rem;

font-family:${fonts.bcExtraLight};

  @media screen and (max-width:600px){
  font-size:1.9rem;

  }

    @media screen and (max-width:515px){
  font-size:1.6rem;

  }


`
const LilaLine6 = styled.div`
display:flex;
font-size:2.3rem;

font-family:${fonts.bcHairLine};

  @media screen and (max-width:600px){
  font-size:1.9rem;

  }

    @media screen and (max-width:515px){
  font-size:1.6rem;

  }


`

const LilaPurple = styled.div`
background-color: #6f6277;
margin-top:1.3rem;
width:80%;
height:27.2px;

`
const LilaGreen = styled.div`
width:89%;
height:27.2px;

background-color:#578280;


`


const LilaSection = styled.div`
display:flex;
justify-content:flex-start;
width:100%;

`




const BroughtToYou = styled.div`
width:100%;
margin-top:30vh;
display:flex;
justify-content:center;
font-family:${fonts.bcHairLine};
font-size:0.7rem !important;
letter-spacing:0.22rem;
opacity:.7;

`

const LilaBrand = styled.div`
height:100vh;
`

const LilaNews = styled.div`

`

const LilaTitle = styled.div`
font-family:${fonts.bcHairLine} !important;
color:var(--txt) !important;
font-size:2.5rem;
padding-bottom:0.44rem;


  @media screen and (max-width:600px){
  font-size:2.1rem;

  }

  @media screen and (max-width:515px){
  font-size:1.5rem;

  }

`

const LilaHaseSvg = () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
  <defs>
  </defs>
  <path className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
  <path className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
  <path className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
  <path className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
        transform="translate(-14.76 -19.7)"/>
  <path className="cls-1"
        d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
        transform="translate(-14.76 -19.7)"/>
  <path className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
        transform="translate(-14.76 -19.7)"/>
  <polygon className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
  <polygon className="cls-3"
           points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
  <polygon className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
  <polygon className="cls-3"
           points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
</svg>


const LilaWrapper = styled.div`
.cls-1{fill:var(--hase);}.cls-2{fill:none;}.cls-3{fill:var(--hase);}
width:18rem;
padding-left:0.66rem;
  @media screen and (max-width:600px){
  width:13rem;
  padding-left:0.4rem;


  }


  @media screen and (max-width:515px){
  width:11rem;
  padding-left:0.2rem;


  }

`

const LilaMobileWelcome = styled.div`
display:none;
padding-left:11%;
padding-top:11%;

  @media screen and (max-width:1140px){
display:block;}
`

const IndexPage = ({pathContext: { locale }}) =>  (
      <div>



        <IndexLayout locale={locale}>
<Consent/>
          <Wrapper>         <HomeSlider/>
          </Wrapper>

          <Page>

          <Container>



            <div>




<LilaWelcome>
  <Fade top>
    <LilaMobileWelcome>
    <LilaTitle><FormattedMessage id={'home_1'}/></LilaTitle>
    <LilaWrapper>
      <LilaHaseSvg/>
    </LilaWrapper>
    </LilaMobileWelcome>
  </Fade>
              <SomethingNew>
               <LilaLine2><Fade top><FormattedMessage id={'home_2'}/></Fade> </LilaLine2>
               <LilaLine1> <Fade top> <FormattedMessage id={'home_3'}/></Fade></LilaLine1>
               <LilaLine3> <Fade top> <FormattedMessage id={'home_4'}/></Fade></LilaLine3>
               <LilaLine4> <Fade top> <FormattedMessage id={'home_4_1'}/></Fade></LilaLine4>
               <LilaLine5> <Fade top> <FormattedMessage id={'home_5'}/></Fade></LilaLine5>
               <LilaLine6> <Fade top > <FormattedMessage id={'home_6'}/></Fade></LilaLine6>

              </SomethingNew>
  <Fade left>    <LilaSection>      <LilaPurple></LilaPurple></LilaSection></Fade>
  <Fade left>       <LilaSection>                <LilaGreen></LilaGreen></LilaSection></Fade>


</LilaWelcome>
                <HighlightWrap>
                  <Highlight/>
                </HighlightWrap>

<LilaNews>
  <HomeNewsletter locale={locale}/>
</LilaNews>

<LilaBrand>
              <ComingSoon><BroughtToYou> <Zoom  right cascade>brought to you by</Zoom>
              </BroughtToYou></ComingSoon>

              <Fade top>
             <LilaHase>

               <Lh>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
                  <defs>
                  </defs>
                  <path className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
                        transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1"
                        d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
                        transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
                        transform="translate(-14.76 -19.7)"/>
                  <polygon className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
                  <polygon className="cls-3"
                           points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
                  <polygon className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
                  <polygon className="cls-3"
                           points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
                </svg>
              </Lh>  </LilaHase>   </Fade>
              <Fade top cascade>
              <Cbd> <CbdP>
              <Span>C</Span>
              <Span>B</Span>
              <Span>D </Span>
              <Span> </Span>
              <Span>B</Span>
              <Span>L</Span>
              <Span>Ü</Span>
              <Span>T</Span>
              <Span>E</Span>
              <Span>N</Span>
              </CbdP>
              </Cbd>
                </Fade>
              <ComingSoon>
                <Fade right cascade>
                  <CountdownWrapper>

                  </CountdownWrapper>
                </Fade>
              </ComingSoon>
              <ComingSoon><Claimer> <P1><Zoom  right cascade>A</Zoom></P1><P2><Zoom  right
                                                                                                cascade>LILACLOUD</Zoom></P2><P3><Zoom
                right cascade>company</Zoom></P3></Claimer></ComingSoon>

</LilaBrand>
            </div>

          </Container>
        </Page>

      </IndexLayout>
      </div>
    )

export default IndexPage




// uncomment to demo the fading. in real life this would be
// called on some event, e.g. something finished loading
/*
setTimeout( function () {
  spinner.finish();
}, 6000)

/*
        <SpinnerDiv style={{display: this.state.loading ? 'flex' : 'none'}}>
          <LilaSymbol>
            <svg className="rotate" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 241.36 179.42">
              <defs>
              </defs>
              <path className="cls-1"
                    d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                    transform="translate(-530.64 -373.08)"/>
              <path className="cls-1"
                    d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                    transform="translate(-530.64 -373.08)"/>
            </svg>
          </LilaSymbol>
        </SpinnerDiv>





        <div>
                  <LilaTxt>In the meantime you can listen to a nice interview.</LilaTxt>
                  <LilaPlayer/>
                  <LilaTxtLight>Q: Who is the 'super chicken' ?</LilaTxtLight>

                </div>
//old content of <LilaBox/>
<MobileBlurLight src={lhBlurWhite}/>
                  <MobileBlurDark src={lhBlurDark} />
                  <LilaMagnifyDark


                    mgHeight={40} mgWidth={40} zoomFactor={2} zoomImgSrc={lhDark} src={lhBlurDark} width={500}/>
                  <LilaMagnifyLight


                    mgHeight={40} mgWidth={40} zoomFactor={2} zoomImgSrc={lhWhite} src={lhBlurWhite} width={500}/>




//loading symbol

         <LilaWrapper>

                      <svg className="rotate" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 241.36 179.42">
                        <defs>
                        </defs>
                        <path className="cls-1"
                              d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                              transform="translate(-530.64 -373.08)"/>
                        <path className="cls-1"
                              d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                              transform="translate(-530.64 -373.08)"/>
                      </svg>
                    </LilaWrapper>


*/

