import * as React from 'react'
import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import ContextConsumer from '../Context'
import { FormattedMessage } from 'react-intl';
import { fonts } from '../../styles/variables'
import * as EmailValidator from 'email-validator';
import BIRDS from 'vanta/dist/vanta.birds.min'
import * as THREE from 'three'
//import { sendNewsletterSuccess } from '../../functions/sesFunctions'
import { store } from 'react-notifications-component';
import Fade from 'react-reveal/Fade';
import { LilaHeart } from '../../constants/lilaIcons'
import { sendNewsletterSuccess } from '../../functions/sesFunctions'

const LilaNewsletter = styled.div`
.notification-content{
background:#578280 !important;
}
position:relative;
height:120vh;
width:100%;

  @media (max-width: 768px) {
height:80vh;  }


*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body {
  font-size: 62.5%;

  @media (max-width: 768px) {
    font-size: 50%;
  }
}
body {
  background: #EDEFF2;
}

$demoW: 36.6rem;
$demoH: 87rem;
$topH: 18.6rem;
$topMaxH: 25.6rem;
$bodyH: 87rem - 18.6rem;
$planeAnimTime: 3.5s;

.demo {
z-index:0;
  position: absolute;
  width: 100%;
  top:-10rem;
  overflow:visible;

  height: 78.6rem !important;
         @media screen and (max-width: 1934px) {
height:74rem !important;
  overflow:visible;

}
         @media screen and (max-width: 1734px) {
height:72rem !important;
  overflow:visible;

}
         @media screen and (max-width: 1434px) {
height:64rem !important;
  overflow:visible;

}

      @media screen and (max-width: 1334px) {
      height:52rem !important;
      top:2%;

}
      @media screen and (max-width: 890px) {
      height:36rem !important;
        overflow:visible;

      top:2%;

}
      @media screen and (max-width: 690px) {
      height:28rem !important;
        overflow:visible;


}
      @media screen and (max-width: 490px) {
      height:18rem !important;
        overflow:visible;


}

  background: none;
  border-radius: 1.2rem;
  box-shadow: 0 2rem 2rem rgba(0,0,0,0.05);
  overflow: hidden;



  &__top {
    position: relative;
    height: 64% !important;
    background:none; // linear-gradient(#6f6277, #6f6277);
    overflow: hidden;

       @media screen and (max-width: 1034px) {
}
  }

  &__body {
    position: relative;
    z-index:9;
    min-height: 100%;
    padding-top: 5rem;
    will-change: transform;




  }
}

.pull-down {
z-index:999;
  @media screen and (max-width: 690px) {
   background:var(--bg);
   z-index:1;
   top:0;


}
  position: absolute;
  left: 0;
  top: 1rem;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  color: rgba(#545C67, 0.6);
  //user-select: none;
 // pointer-events: none;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 1rem;
    //height: 1rem;
    border: 1px solid rgba(#545C67, 0.6);
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
    animation: arrowAnim 1.5s infinite;
  }

  &:before {
   // left: 71rem;
  }
  &:after {
  //  left: 25rem;
  }
}

@keyframes arrowAnim {
  to {
    transform: translateY(1.3rem) rotate(45deg);
    opacity: 0;
  }
}

.items {
  position: relative;

  &.padded {
    transition: padding 0.3s;
    padding-top: 8rem;
  }
}

.item {
  height: 8rem;
  padding: 2rem 2.5rem;
  user-select: none;
  transition: opacity 0.3s;

  &.absPos {
    position: absolute;
    left: 0;
    top: 0;
  }

  &.hidden {
    opacity: 0;
  }

  &__icon {
    display: inline-block;
    vertical-align: top;
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    border-radius: 50%;

    &.animated {
      animation: animateIcon 0.6s forwards;
    }

    &.m--img {

      img {
        width: 100%;
      }
    }
  }

  &__name {
    font-size: 2rem;
    line-height: 4rem;
    color: #545C67;
  }
}

@keyframes animateIcon {
  20% {
    transform: scaleY(0.7);
  }
  40% {
    transform: scaleY(0.9);
  }
  60% {
    transform: scaleY(0.6);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.plane-cont {
  position: absolute;
  left:10%;
  top: -2.4rem;
  width: 4.6rem;
  height: 4.6rem;
  background: var(--bg);
  border-radius: 50%;
  box-shadow: 0 0.3rem 0.3rem rgba(0,0,0,0.2);
  z-index:9;
}

.plane-rotater {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.7rem;
  margin-top: -0.9rem;
  width: 1.8rem;
  height: 1.6rem;
}

.plane {

  &.fly {
    animation: planeFly 3.5s forwards;
  }
}

@keyframes planeFly {
  28% {
    transform: translate(55rem, 13rem) rotate(20deg) scale(0.7);
  }
  35% {
    transform: translate(45rem, -8rem) rotate(-160deg) scale(0.5);
  }
  85% {
    transform: translate(-15rem, -4rem) rotate(-180deg) scale(0.7);
  }
  90% {
    transform: translate(-15rem, 0) rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.svgBg {

  &__bg {
    transform-origin: 183px 256px;
  }

  &__tree {

    &-trunk {
      fill: #466866;
    }

    &-part {
      transform-origin: inherit;
    }

    &-1 {
      transform-origin: 54px 147px;
      opacity: 0.7;

      .svgBg__tree-part {
        transform: scale(0.35, 0.44);
      }
    }

    &-2 {
      transform-origin: 67px 144px;
      opacity: 0.7;

      .svgBg__tree-part {
        transform: scale(0.56, 0.65);
      }
    }

    &-3 {
      transform-origin: 264px 149px;

      .svgBg__tree-part {
        transform: scale(0.58, 0.65);
      }
    }

    &-4 {
      transform-origin: 287px 148px;

      .svgBg__tree-part {
        transform: scale(0.8, 1);
      }
    }

    &-5 {
      transform-origin: 313px 148px;

      .svgBg__tree-part {
        transform: scale(0.5, 0.61);
      }
    }
  }
}

/*
font awesome icon slicing effect from https://codepen.io/suez/pen/KpwEeg
*/
$boxSize: 4rem;
$fontSize: $boxSize * 0.6;
$xDiff: $boxSize / 20;
$yDiff: $boxSize / 10;
$animTime: 0.5s;
// change this if you need another angle (now works between 0 and 45 deg)
$deg: 30deg;
$degCoef: $deg / 45deg;
$sliceLeft: (1 - $degCoef) / 2 * 100%;
$sliceX: $xDiff + (1 - $degCoef) / 2 * $xDiff + 0.1rem;
.icon-box {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: $boxSize;
  height: $boxSize;
  background: #0c0c0c;
  overflow: hidden;
  font-size: 0;
  text-decoration: none;
  border-radius: 50%;

  &:before {
    content: "";
    position: absolute;
    left: $sliceLeft;
    top: 0;
    margin-left: -1px;
    width: 2px;
    height: 0.6rem;
    background: #fff;
    transition: transform $animTime;
    transform: rotate($deg * -1) translate($sliceX, -1rem);
  }

  &:hover:before {
    animation: slice $animTime;
  }

  &__inner {
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    width: 50%;
    height: 100%;
    transform: rotate($deg * -1);
    font-size: $fontSize;
    color: #fff;

    .fa {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) rotate($deg);
    }

    &.m--left {
      transform-origin: 100% 50%;

      .fa {
        left: 100%;
      }
    }

    &.m--right {
      transform-origin: 0 50%;
      transition: transform $animTime;

      .icon-box:hover & {
        transition: transform $animTime $animTime*0.2;
        transform: rotate($deg * -1) translate($xDiff, $yDiff);
      }

      .fa {
        left: 0;
      }
    }
  }
}

@keyframes slice {
  to {
    transform: rotate(-$deg) translate($sliceX, $boxSize*1.5 + 1rem);
  }
}

`

const LilaNoSpam = styled.div`

padding-left:5%;
padding-right:5%;

`
const LilaPhrase =styled.div`
margin-top:2.1rem;
font-size:.9rem;
font-family:${fonts.bcHairLine};


margin-bottom:2.1rem;
`
const LilaMission = styled.div`
font-size:1.56rem;
margin-top:3.4rem;
font-family:${fonts.bcHairLine};


`

const LilaForm = styled.form`
.Mui-error{
}
.MuiFilledInput-root{
background:none !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--border);
  -webkit-text-fill-color: var(--txt);
  -webkit-box-shadow: 0 0 0px 1000px var(--bg) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.input:-internal-autofill-selected {

background:var(--bg) !important;
color:var(--txt) !important;

}



.MuiInputBase-root{
color:var(--txt) !important;
opacity:1;
font-family:${fonts.bcBook};
width:300px;

 @media screen and (max-width: 334px) {
width:280px !important;
}
}

.MuiFormControl-root MuiTextField-root{
color:var(--txt) !important;
background: rgba(0,0,0,0.00); !important;
}
.MuiFormLabel-root{
font-family:${fonts.bcLight};
opacity:.5;
color:var(--txt) !important;

}

.MuiFormLabel-root.Mui-focused{


color:var(--hase) !important;


}
.MuiInputBase-input{
background-color: rgba(0,0,0,0.03);

:hover{
background:rgba(0,0,0,.06)
}

:focus{
color:var(--txt) !important;
}
}

.MuiFilledInput-underline {
::after{
border-bottom-color:var(--hase) !important;
}

::before{
 border-bottom-color:var(--border) !important;

}
}





.MuiInputBase-input MuiFilledInput-input{
color:var(--txt);
background: rgba(0,0,0,0.00) !important;
}
`

const LilaName = styled.div`
display:flex;
justify-content:center;

margin-bottom:1.2rem;

 @media screen and (max-width: 1199px) {
 width:100%;
`
const LilaSubmitWrapper = styled.div`
width:100%;
display:flex;
justify-content:center;
position:relative;
z-index:999;
`
const LilaSubmit = styled.button`
margin-top:1.1rem;

font-family:${fonts.bcExtraLight};
border:none;
cursor:pointer;
width:250px;
height:35px;
display:flex;
justify-content:center;
align-items:center;
background: var(--haase);
color:white;
  border-radius:18px;
    opacity:1;
transition: opacity .4s ease;

:hover{
opacity:1;
text-decoration:underline;
}
`

const LilaValidEmail = styled.div`
font-family:${fonts.bcHairLine};
width:100%;
display:flex;
padding-top:1.1rem;
justify-content:center;

`

const LilaFade = styled.div`
transition:opacity .4s ease;
`

const LilaBirds = styled.div`
position:absolute;
background:none;
z-index:0;
height:64%;
width:100%;

`

const LilaThankYou = styled.div`
width:100%;
padding-left:10%,
padding-right:10%;
font-family:${fonts.bcHairLine};
font-size:1.56rem;

`

const LilaHeartWrap = styled.div`
width:100%;
display:flex;
justify-content:center;
padding-bottom:2.1rem;
padding-top:6.1rem;
svg{
width:56px;

}
`

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


class HomeNewsletter extends React.Component {
  constructor(props: any) {
    super(props)
    this.vantaRef = React.createRef()
    this.state = {
      _lila_cus_mail:'',
      showSubmit:false,
      showError:false,
      completed:false
    }
    this.handleSubmit=this.handleSubmit.bind(this)
  }

  handleSubmit = e => {
    e.preventDefault();

    console.log('submitted')
    console.log(this.props.locale)


    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "lh-newsletter", ...this.state })
    })
      .then(() => {
        // send success mail
        sendNewsletterSuccess(this.state._lila_cus_mail, this.props.locale)

        this.setState({completed:true})

        // show notification
        store.addNotification({
          title: <FormattedMessage id={'news_success_1'}/>,
          message: <FormattedMessage id={'news_success_2'}/>,
          type: "info",
          insert: "right",
          container: "bottom-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

      })
      .catch(error => alert(error));

  };

  setLilaInput(set, k, v){
    if(k==='mail'){

      if(EmailValidator.validate(v)){
        this.setState({
          showSubmit:true,
          showError:false,
          clicked:false
        })}
        else {
          if(v===''){
            this.setState({
              showError:false,
              showSubmit: false

            })
          }
          else {
            this.setState({
              showSubmit: false,
              showError:true
            })
          }
        }
      }
      set({_lila_cus_mail:v})
    this.setState({_lila_cus_mail:v})
    }



  componentDidMount() {
    this.vantaEffect = BIRDS({
      THREE: THREE,
      el: this.vantaRef.current,
      touchControls: true,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color1: 0x578280,
      color2: 0x7e287e,
      colorMode: "lerpGradient",
      birdSize: 0.80,
      alignment: 40.00,
      quantity: 3.00,
      backgroundAlpha: 0.00

      // use a custom THREE when initializing
    })
require('./paperPlane.js')

  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy()
  }

  render() {

    return(

      <ContextConsumer>
        {({ data, set }) => (
          <div>
        <LilaNewsletter id={'component'} >

          <div className="demo">
            <LilaBirds  ref={this.vantaRef}/>
            <Fade delay={200}>
            <div className="demo__top">

              <svg className="demo__top-svgBg" viewBox="0 0 366 256" >
                <g className="svgBg__objects">
                  <path className="svgBg__bg svgBg__bg1" fill="#709f9d"
                        d="M0,143 88,107 224,153 348,109 366,123 366,256 0,256z"/>
                  <path className="svgBg__bg svgBg__bg2" fill="#578280"
                        d="M0,156 106,136 272,172 342,124 366,144 366,256 0,256z"/>
                  <path className="svgBg__bg svgBg__bg3" fill="#6f6277"
                        d="M0,170 62,160 235,148 305,145 366,153 366,256 0,256z"/>
                  <g className="svgBg__tree svgBg__tree-1 m--left" data-id="1">
                    <path className="svgBg__tree-leafs svgBg__tree-part" fill="#578280"
                          d="M54,127 C77,127 62,95 54,63 C46,95 31,127 54,127"/>
                    <path className="svgBg__tree-trunk svgBg__tree-part" d="M56,147 Q55,115 54,83 Q53,115 52,146"/>
                  </g>
                  <g className="svgBg__tree svgBg__tree-2 m--left" data-id="2">
                    <path className="svgBg__tree-leafs svgBg__tree-part" fill="#578280"
                          d="M67,124 C90,124 75,92 67,59 C59,92 44,124 67,124"/>
                    <path className="svgBg__tree-trunk svgBg__tree-part" d="M69,144 Q68,112 67,80 Q66,112 65,143"/>
                  </g>
                  <g className="svgBg__tree svgBg__tree-3 m--right" data-id="3">
                    <path className="svgBg__tree-leafs svgBg__tree-part" fill="#578280"
                          d="M264,129 C287,129 272,97 264,64 C256,97 241,129 264,129"/>
                    <path className="svgBg__tree-trunk svgBg__tree-part" d="M266,149 Q265,117 264,85 Q263,117 262,148"/>
                  </g>
                  <g className="svgBg__tree svgBg__tree-4 m--right" data-id="4">
                    <path className="svgBg__tree-leafs svgBg__tree-part" fill="#578280"
                          d="M287,128 C310,128 295,96 287,63 C279,96 264,128 287,128"/>
                    <path className="svgBg__tree-trunk svgBg__tree-part" d="M289,148 Q288,116 287,84 Q286,116 285,147"/>
                  </g>
                  <g className="svgBg__tree svgBg__tree-5 m--right" data-id="5">
                    <path className="svgBg__tree-leafs svgBg__tree-part" fill="#578280"
                          d="M313,128 C336,128 321,96 313,63 C305,96 290,128 313,128"/>
                    <path className="svgBg__tree-trunk svgBg__tree-part" d="M315,148 Q314,116 313,84 Q312,116 311,147"/>
                  </g>
                </g>
              </svg>

            </div>
            </Fade >
            <div className="demo__body">
              <div className="plane-cont">
                <div className="plane-rotater">
                  <div className="plane">
                    <svg className="plane-svg" viewBox="0 0 28 26">
                      <path className="plane-svg__path" fill="var(--hase)" d="M0,0 28,13 0,26 0,13 20,13 0,7z"/>
                    </svg>
                  </div>
                </div>
              </div>

              <LilaNoSpam className="pull-down">
                {this.state.completed &&
                <Fade top>
                  <LilaHeartWrap><LilaHeart/></LilaHeartWrap>
                  <LilaThankYou><FormattedMessage id={'news_success_3'}/></LilaThankYou>

                </Fade>
                }
                {!this.state.completed &&
                <div>
                  <Fade top><LilaMission><FormattedMessage id={'news_1'}/>
                  </LilaMission></Fade>
                  <LilaFade>
                    <Fade top>
                      <LilaPhrase> <FormattedMessage id={'news_2'}/> <br/><FormattedMessage id={'news_3'}/>
                      </LilaPhrase>
                    </Fade>
                  </LilaFade>
                  <Fade top>
                    <LilaForm name="lh-newsletter" method="POST" data-netlify="true" onSubmit={this.handleSubmit}>
                      <input type="hidden" name="form-name" value="lh-newsletter" />
                      <LilaName className={'input_email'} onClick={() => this.setState({ clicked: true })}> <TextField
                        required type="email" id="filled-basic" label="Filled" variant="filled"
                        label={<FormattedMessage id={'checkout_1'}/>}
                        value={this.state._lila_cus_mail}
                        onChange={(e) => this.setLilaInput(set, 'mail', e.currentTarget.value)}>
                        <Input
                          name="email"
                          required type="email"/>
                      </TextField>
                      </LilaName>
                      <Fade bottom spy={this.state.showError}> <LilaValidEmail
                        style={{ display: this.state.showError ? 'flex' : 'none' }}><FormattedMessage
                        id={'email_0'}></FormattedMessage></LilaValidEmail></Fade>
                      <Fade bottom spy={this.state.showSubmit}><LilaSubmitWrapper
                        style={{ display: this.state.showSubmit ? 'flex' : 'none' }}
                        className={'btn_submit'}><LilaSubmit><FormattedMessage
                        id={'btn_submit'}></FormattedMessage></LilaSubmit></LilaSubmitWrapper></Fade>
                    </LilaForm>
                  </Fade>
                </div>
                }
              </LilaNoSpam>
              <div className="items">


              </div>


            </div>
          </div>

    </LilaNewsletter>
          </div>
          )}
          </ContextConsumer>



    )
  }
}


export default HomeNewsletter


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
