import * as React from 'react'
import styled from '@emotion/styled'
import Popup from 'reactjs-popup'
import LilaImg1 from './LilaImg1'
import { FormattedMessage } from 'react-intl';
import { colors, fonts } from '../../styles/variables'
import { useEffect } from 'react'
import LocalizedLink from '../links/LocalizedLink'

const StyledPopup:any = styled(Popup)`


.popup-content{
width:420px;
}
`

const LilaConsent = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;

`



const PopWrapper = styled.div`
position:relative;
z-index:999999999;
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: flex;
  padding: 2px 5px;
  line-height: 20px;
  right: 14px;
  top: 14px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 50%;
    border: 1px solid #cfcece;
    z-index:999999999  !important;
width:37px;
height:37px;
justify-content:center;
text-align:center;


}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  //border-radius: 2px;
  font-weight: 600;
  text-transform: uppercase;
  //letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

form {
margin-top:40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
  width:400px;
    margin-left: auto;
margin-right: auto;

  @media screen and (max-width:960px){
width:320px;
  margin-left: auto;
margin-right: auto;
  }

}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}


input {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 2px;
  background: white;
  border:none;
}


.modal {
background:var(--bg);
  width:100%;



  @media screen and (max-width:960px){
  width:100%;
  }
}

.popup-content {
display:flex;
justify-content:center;
width:45% !important;

align-items:center;
border:none !important;
padding:0px !important;
border-radius:2px;

@media screen and (max-width:760px){
width:100% !important;
}

}

.popup-overlay{
z-index:999999 !important;
}

.CardDemo{
  @media screen and (max-width:960px){
  width:100% !important;
  }
}

`





const LilaInfoTxt = styled.div`
margin-top:5%;
margin-bottom:5%;
padding-left:5%;
padding-right:5%;
font-family:${fonts.bcExtraLight};


`
const LilaH1 = styled.h1`
margin-top:5%;
font-size:1.4rem;
padding-left:5%;
font-family:${fonts.bcBook};
`

const LilaRipples = styled.div`
cursor:pointer;
background:#311e69;
border-radius:18px;
letter-spacing:1px;
           // border-bottom: 1px solid var(--txt);
            //border-top: 1px solid ;
            width:250px;

            height:35px;
            color:${colors.fontDark};
display:flex;
justify-content:center;
align-items:center;
`

const LilaConsentFooter = styled.div`
width:100%;
display:flex;
padding-left:5%;
padding-right:5%;
justify-content:space-between;
padding-bottom:5%;
align-items:center;

`

const LilaLink = styled.a`
font-family:${fonts.bcHairLine};
font-size:0.9rem;
text-align:right;
`

const LilaLink2 = styled(LocalizedLink)`
font-size:0.9rem;
color:var(--hase);
padding-left:0.5rem;
cursor:pointer;
`

const Nonvisible = styled.div`
display:none;
`

const StateToLocal = (props) => {

  useEffect(() => {



      window.localStorage.setItem('_lila_cookie_consent', props.popupOpen)



    },
    [props.popupOpen]
  );




  return <Nonvisible/>

}


class Consent extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
      popupOpen:true,
      paymentOpen:false,
      _lila_cookie_consent:false

    }
    this.closePopup=this.closePopup.bind(this);

  }


closePopup(){
    this.setState({
      popupOpen:false
    })
}
  componentDidMount() {


    const isItem = (item) => {
      if (window.localStorage.getItem(item) != undefined) {
        return true
      }
      return false

    };

    this.setState({
      popupOpen: isItem('_lila_cookie_consent') ? JSON.parse(window.localStorage._lila_cookie_consent) : true,




  })




  }

  render() {
    return(


        <LilaConsent id={'component'}>
          <StateToLocal popupOpen={this.state.popupOpen}/>
          <PopWrapper>
            <StyledPopup
              lockScroll={false}
              closeOnDocumentClick={false}
              open={this.state.popupOpen}

              onClose={this.closePopup}
            >
              <div className="modal">


                <LilaH1><FormattedMessage id={'cookie_0'}/></LilaH1>

                <LilaInfoTxt>
  <FormattedMessage id={'cookie_1'}/><br/><br/>
  <FormattedMessage id={'cookie_2'}/>
                  <LilaLink2 to={'/datenschutz'}><FormattedMessage id={'cookie_2_1'}/></LilaLink2>

</LilaInfoTxt>
                <LilaConsentFooter><LilaRipples onClick={()=>this.closePopup()}><FormattedMessage id={'cookie_3'}></FormattedMessage></LilaRipples> <LilaLink href="javascript:history.back()"><FormattedMessage id={'cookie_4'}/></LilaLink></LilaConsentFooter>
                <LilaImg1/>

              </div>        </StyledPopup>
          </PopWrapper>


    </LilaConsent>



    )
  }
}


export default Consent


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
