import * as React from 'react'
import styled from '@emotion/styled'
import { Parallax } from "react-parallax";
import { fonts } from '../../styles/variables'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
const LilaBud = require('../../../assets/video/lila-bud3.mp4')
import Ripples from 'react-ripples'

import { FormattedMessage } from 'react-intl';
import AddToCart from '../cart/AddToCart'
import LocalizedLink from '../links/LocalizedLink'

import Plx from 'react-plx';
import {
  LilaCert,
  LilaCert2,
  LilaGlass,
  LilaShop,
  LilaSpot,
  LilaStats,
  LilaStats2,
  LilaWeight
} from '../../constants/lilaIcons'
import * as THREE from 'three'
import WAVES from 'vanta/dist/vanta.waves.min'
import ZkittlezImg01 from '../products/zkittlez/ZkittlezImg01'
import Ticker from 'react-ticker'

const rightPlxData = [
  {
    start: '.lilaHighlight',
    end: '.strainInfo',
    properties: [
      {
        startValue: 0,
        endValue: 21.9,
        property: 'translateX',
        unit:'%'

      }

    ],
  },
];

const leftPlxData = [
  {
    start: '.lilaHighlight',
    end: '.strainInfo',
    properties: [
      {
        startValue: 0,
        endValue: -21.9,
        property: 'translateX',
        unit:'%'

      }


    ],
  },
];



const LilaCaption = styled.div`
width:100%;
display:flex;
padding-top:29.3%;
justify-content:space-between;
color:white !important;

  @media screen and (max-width:1058px){
padding-top:3%;
}
`
const LilaCbd = styled.div`
right:0;
bottom:1.8rem;
padding-right:21%;
padding-top:2rem;

position:absolute;
color:var(--icon1);
font-size:1.8rem;
font-family:${fonts.bcHairLine};
transition:all .3s ease;


`

const LilaMobileHighlight = styled.div`
width:100%;
display:none;

  @media screen and (max-width:1058px){
display:block;

}
`



const LilaHighlight = styled.div`
width:100%;
display:flex;
position:relative;

padding-left:11%;
padding-right:11%;
height:50%;
 img {
    pointer-events: none;

  }

  @media screen and (max-width:1058px){
display:none;

}

`



const LeftPlx = styled(Plx)`
width:50%;
position:absolute;
left:11%;
z-index:99;


`
styled(Parallax)`
display:flex;
align-items:flex-start;
height:auto;
width:100%;
`


const LilaTitle=styled.div`
font-family:${fonts.bcExtraLight};
//font-size:0.7rem;
display:flex;
align-items:center;
a{color:white;}


.selected{
color:#578280;
background:white;
}

`

const LilaButtons=styled.div`
//padding-top:1rem;
padding-left:1.1rem;
padding-right:1.1rem;
font-size:0.9rem;

.react-ripples{
border: 1px solid white;
color:white !important;
cursor:pointer;
font-family:${fonts.bcExtraLight};
display:flex;
justify-content:center;
align-items:center;
height:30px;

border-radius:18px;




}
font-family:${fonts.bcExtraLight};

z-index:99;
//padding-left:54px;
display:flex;
justify-content:space-between;
width:100%;

`
const LilaButtons2=styled.div`

  @media screen and (max-width:1058px){
display:none;

}

a{
color:white !important;
}
}

font-family:${fonts.bcExtraLight};
font-size:0.9rem;
z-index:99;
padding-right:2%;
display:flex;
//padding-top:1rem;

justify-content:flex-end;
width:50%;

.react-ripples{
border: 1px solid white;
cursor:pointer;
font-family:${fonts.bcExtraLight};
display:flex;
justify-content:space-between;
align-items:center;
height:30px;
border-radius:18px;





}


`



const LilaMobileButtons=styled.div`
display:none;
width:100%;

  @media screen and (max-width:1058px){
display:flex;
justify-content:flex-end;
padding-right:3%;

.react-ripples{
border:1px solid white !important;
color: white !important;
cursor:pointer;
font-family:${fonts.bcExtraLight};
display:flex;
justify-content:center;
align-items:center;
height:30px;

border-radius:18px;



}
}

}




font-family:${fonts.bcHairLine};
font-size:0.9rem;


justify-content:space-between;

`
const LilaMobileButtons2=styled.div`
display:none;
width:100%;

  @media screen and (max-width:1058px){
display:flex;
justify-content:center;
padding-top:0%;
padding-bottom:15vh;

.react-ripples{
border:1px solid white !important;
color: white !important;
cursor:pointer;
font-family:${fonts.bcExtraLight};
display:flex;
justify-content:center;
align-items:center;
height:30px;

border-radius:18px;

}

}




font-family:${fonts.bcHairLine};
font-size:0.9rem;



`

const LilaRipple = styled(Ripples)`
display:flex;
justify-content:center;
align-items:center;
border: 1px solid var(--border);
border-radius:2px;
`

const LilaLink = styled(LocalizedLink)`
padding: 0.3rem 0.6rem;
display:flex;
justify-content:center;
align-items:center;
color:white !important;


:hover{
text-decoration-color:var(--border);
}

a{
color:white !important;

}
`

const RightPlx = styled(Plx)`
width:50% !important;
height:auto !important;
position:absolute;

right:11%;
z-index:9999;

:hover{

${LilaCbd}{
padding-right:23%;
bottom:0.9rem;
}

}




`




const StrainInfoWrapper = styled.div`
width:100%;
display:flex;
font-size:0.9rem;
justify-content:center;
font-family:${fonts.bcHairLine};
background:var(--bw);


  @media screen and (max-width:1058px){
margin-top:0rem;
margin-bottom:0rem;
}
`
const StrainInfoBackground = styled.div`
width:100%;
display:flex;

background:var(--bg);


  @media screen and (max-width:1058px){
margin-top:0rem;
margin-bottom:0rem;
}
`






const TwoGrams = styled.div`
cursor:pointer;
font-family:${fonts.bcExtraLight};

width:30px;
height:30px;
border-radius:50%;
background:none;
justify-content:center;
align-items:center;
display:flex;
margin-right:1rem;
margin-left:1rem;


.selected{
background:#7e287e;
color:white;
}


`

const FiveGrams = styled.div`
font-family:${fonts.bcExtraLight};

cursor:pointer;
width:30px;
height:30px;
border-radius:50%;
background:none;
justify-content:center;
align-items:center;
display:flex;


.selected{
background:#7e287e;
color:white;
}
`




const LilaFlex = styled.div`
display:flex;
padding-top:0.9%;
padding-bottom:0.9%;
position:relative;
align-items:center;


z-index:-1;
//justify-content:center;

color:var(--txt) !important;



  @media screen and (max-width:1058px){


  }

`

const LilaRow = styled.div`
margin-right:9rem;
color:var(--txt2) !important;
  @media screen and (max-width:632px){
margin-right:4rem;
font-size:0.7rem;


}


font-size:1.1rem;
display:flex;
justify-content:center;
  white-space: nowrap;
  align-items:center;

font-family:${fonts.bcExtraLight};
svg{
width:36px;


  @media screen and (max-width:1058px){
width:24px;
}

}




`


const LilaMiddle = styled.div`
  position: absolute;

  left: 50%;



  transform: translateX(-50%);
  .react-ripples{
  border:1px solid white;
  }

    @media screen and (max-width:1058px){
display:none;

}
`
const LilaCell = styled.div`
align-items:center;
border:none;


padding-left:1.4rem;

  @media screen and (max-width:1058px){
padding-left:1.2rem;
}


`
const LilaCell1 = styled.div`
align-items:center;
padding-right:3%;
display:flex;
border:none;

`


const LilaTitle1 = styled.div`
font-family:${fonts.bcHairLine};
width:220px;

display:flex;
justify-content:flex-end;
padding-right:1.6rem;
ß
`
const LilaTitle2 = styled.div`
font-family:${fonts.bcHairLine};
width:220px;
padding-left:1.6rem;

`

const LilaDeliverySection = styled.div`
:hover{
.cls-1{
fill:var(--hase);
}

.cls-2{
m
}

}
height:62px;
display:flex;
justify-content:center;
align-items:center;
width:100%;
padding-left:10%;
padding-right:10%;
//border-top: 2px solid white;
background:var(--bw) !important;

  @media screen and (max-width:860px){
display:none;
  }

`

const LilaVideo = styled.video`
pointer-events:none;

  @media screen and (max-width:760px){
pointer-events:auto;
  }
`



const LilaLocation = styled.div`

height:10vh;
display:flex;
justify-content:center;
align-items:center;

svg{
width:24px !important;

.cls-1{
fill: white !important;
}

.cls-2{
fill: white !important;

}
}




.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 50px;
  height: 50px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 180%;
    height: 180%;
    box-sizing: border-box;
    margin-left: -60%;
    margin-top: -60%;
    border-radius: 50%;
    background-color: var(--headerBg);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.2);
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}
`

const LilaSpotWrapper = styled.div`
svg{
width:28px !important;

  @media screen and (max-width:1058px){
width:20px !important;
}
}


`

const LilaTicker = styled.div`

background:var(--bw);
color:var(--icon1) !important;

.ticker {
  position: relative;
  z-index:1;
}

.ticker::before,
.ticker::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25vw;
      z-index:999999;

}
.ticker::before {
  left: 0;
  top:0;
    z-index:99999999;

  background-image: linear-gradient(to right, var(--bg), var(--transparent))
}
.ticker::after {
  z-index:999999999;
  right: 0;
  background-image: linear-gradient(to left, var(--bg), var(--transparent))
}
`

class Highlight extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
      spin:false,
      move:true,
twoGramsSelected:true,
fiveGramsSelected:false,

    }
    this.vantaRef = React.createRef()

    this.selectTwoGrams=this.selectTwoGrams.bind(this)
    this.selectFiveGrams=this.selectFiveGrams.bind(this)
  }

  selectTwoGrams() {
    this.setState({
      twoGramsSelected:true,
      fiveGramsSelected:false
    })
  }

  selectFiveGrams() {
    this.setState({
      twoGramsSelected:false,
      fiveGramsSelected:true
    })
  }



  componentDidMount() {
    this.vantaEffect = WAVES({
      el: this.vantaRef.current,
      color:0x466866,
      zoom: 0.75,
      waveSpeed:0.65,
      THREE: THREE // use a custom THREE when initializing
    })
  }

  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy()
  }



  render() {
    return(

<div style={{zIndex:2,position:'relative'}}>

  <LilaMobileHighlight>
  <div style={{ display:'flex', alignItems:'flex-end',width: '100%', height: 'auto' }}>
    <LilaVideo  onClick={()=>{return false}} muted loop={true} autoPlay={true} style={{ width: '100%', height: 'auto' }} controls={false}>
      <source src={LilaBud} type="video/mp4" />
    </LilaVideo>
  </div>
  <ZkittlezImg01/>


</LilaMobileHighlight>

  <LilaHighlight className='lilaHighlight' id={'lilaHighlight'}>


      <LeftPlx parallaxData={  leftPlxData} className={'_lila_front_plx'}>
          <ZkittlezImg01/>


          </LeftPlx>







    <RightPlx parallaxData={  rightPlxData} className={'_lila_front_plx'} >

              <video  muted loop={true} autoPlay={true} style={{width:'100%'}} controls={false}>
                <source src={LilaBud} type="video/mp4" />
              </video>


    </RightPlx>



    </LilaHighlight>
<div ref={this.vantaRef}>
  <LilaCaption>

    <LilaButtons>
      <Fade bottom>
      <LilaTitle>  <LocalizedLink to={'/zkittlez'}>ZKITTLEZ</LocalizedLink>  <TwoGrams style={{border:this.state.twoGramsSelected?'1px solid rgba(255,255,255,0.0)':'1px solid white'}} onClick={this.selectTwoGrams} className={this.state.twoGramsSelected?"grams selected":"grams unselected"}>2g</TwoGrams>
        <FiveGrams style={{border:!this.state.twoGramsSelected?'1px solid rgba(255,255,255,0.0)':'1px solid white'}} onClick={this.selectFiveGrams} className={this.state.twoGramsSelected?"grams unselected":"grams selected"}>5g</FiveGrams></LilaTitle></Fade>
      <LilaMiddle><Fade bottom><AddToCart id={this.state.twoGramsSelected?0:1}/> </Fade>  </LilaMiddle> </LilaButtons>
    <LilaMobileButtons>    <Fade bottom><AddToCart id={this.state.twoGramsSelected?0:1}/> </Fade></LilaMobileButtons>
    <LilaButtons2>
      <Fade bottom>
      <LilaRipple><LilaLink to={'/cbd-blueten'}><Zoom cascade right><FormattedMessage id={'cart_001'}/></Zoom></LilaLink></LilaRipple>
      </Fade>
    </LilaButtons2>
  </LilaCaption>

<LilaLocation onMouseEnter={()=>this.setState({spin:true})}>

</LilaLocation>

  <LilaMobileButtons2>      <LilaRipple><LilaLink to={'/cbd-blueten'}><Zoom cascade right><FormattedMessage id={'cart_001'}/></Zoom></LilaLink></LilaRipple>
  </LilaMobileButtons2>
</div>

  <LilaTicker
    className={'strainInfo'} id={'strainInfo'}
    onMouseEnter={() => this.setState({move:false})}
    onMouseLeave={() => this.setState({move:true})}
  >
    <Ticker
      speed={3}
      mode={'chain'}
      move={this.state.move}

    >
      {({ index }) => (
        <>

          <LilaFlex>

            <LilaRow>
              <LilaCell1><Zoom> <LilaCert></LilaCert></Zoom></LilaCell1>
              <LilaCell> <Fade bottom><FormattedMessage id={'highlight_1'}/></Fade></LilaCell>
            </LilaRow>

            <LilaRow>
              <LilaCell1> <Zoom><LilaStats/></Zoom></LilaCell1>
              <LilaCell>

                <Fade bottom><FormattedMessage id={'highlight_2'}/>
                  <FormattedMessage id={'highlight_3'}/></Fade>

              </LilaCell>

            </LilaRow>
            <LilaRow>
              <LilaCell1> <Zoom><LilaWeight></LilaWeight></Zoom></LilaCell1>
              <LilaCell>

                <Fade bottom> {'2g | 5g'}
              </Fade>

              </LilaCell>

            </LilaRow>
            <LilaRow>
              <LilaCell1> <Zoom><LilaGlass></LilaGlass></Zoom></LilaCell1>
              <LilaCell>

                <Fade bottom> <FormattedMessage id={'strainInfo_2'}></FormattedMessage>
              </Fade>

              </LilaCell>

            </LilaRow>
            <LilaRow>
              <LilaCell1> <Zoom> <LilaShop/></Zoom></LilaCell1>
              <LilaCell>

                <Fade bottom> <FormattedMessage id={'origin_berlin'}></FormattedMessage>
              </Fade>

              </LilaCell>

            </LilaRow>
            <LilaRow>
              <LilaCell1> <Zoom><LilaSpotWrapper><LilaSpot/></LilaSpotWrapper> </Zoom></LilaCell1>
              <LilaCell>

                <Fade bottom> <FormattedMessage id={'delivery_3'}></FormattedMessage>
              </Fade>

              </LilaCell>

            </LilaRow>

          </LilaFlex>
        </>
      )}
    </Ticker>
  </LilaTicker>
</div>



    )
  }
}


export default Highlight


/*
  <Fade bottom style={{background:'var(--bg)'}}>
    <StrainInfoBackground>
    <StrainInfoWrapper className={'strainInfo'} id={'strainInfo'}>
      <LilaDeliverySection> <LilaTitle1><FormattedMessage id={'delivery_3'}/></LilaTitle1><LilaSpot/> <LilaTitle2><FormattedMessage id={'delivery_5'}/></LilaTitle2></LilaDeliverySection>
    </StrainInfoWrapper></StrainInfoBackground></Fade>
 */

//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
