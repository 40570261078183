import * as React from 'react'
import styled from '@emotion/styled'
import ContextConsumer from "../Context"
import Ripples from 'react-ripples'
import { fonts } from '../../styles/variables'
import { FormattedMessage } from 'react-intl';


const LilaAddToCart = styled(ContextConsumer)`


`

const LilaBtn = styled(Ripples)`
border: 1px solid var(--border);
border-radius:18px;
height:2.2rem;
display:flex;
justify-content:center;
padding: 0.0rem 0.9rem;
cursor:pointer;
font-family:${fonts.bcExtraLight};
display:flex;
justify-content:center;
align-items:center;
:hover{
text-decoration:underline;
text-decoration-color:var(--border);
}
`


class AddToCart extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }

  setTotal(set, items){
    let val =  0;
    for(let i=0;i<items.length;i++){
      if(items[i].quantity>0){
        val += items[i].amount;
      }
    }
    set({ total: val })
  }

addItemToCart(id, set, data){
    let items = data.cartItems;
    let item = data.cartItems[id];
    if(item.quantity<10) {
      item.quantity++;
      item.amount = item.price * item.quantity;
    }
    items[id]= item;

  set({
      cartItems: items,
      cartOpen:true
  })
  this.setTotal(set, items)

}

  componentDidMount() {

  }

  render() {
    return(


        <LilaAddToCart className={'_lila_addToCart'}>

            {({ data, set }) => (
              <LilaBtn onClick={() => this.addItemToCart(this.props.id, set, data)}><FormattedMessage id={'cart_000'}/></LilaBtn>


            )}



    </LilaAddToCart>



    )
  }
}


export default AddToCart


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
